<template>
    <v-container>
        <VTopToolbar
            title="Payment Details"
            :showBack="true"
            id="payment-toolbar"
        >
            <template slot="right">
                <v-icon :disabled="!phaseInfo" @click="showActionSheet">
                    mdi-dots-horizontal
                </v-icon>
            </template>
        </VTopToolbar>

        <div v-if="loading">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        </div>
        <div v-else>
            <h3>{{ payment.title }} &ndash; {{ payment.phaseName }}</h3>
            <div class="mb-2" v-if="payment.reference">
                <label class="section-label">Reference</label>
                <div>{{ payment.reference }}</div>
            </div>
            <div class="mb-2" v-if="payment.createdDate">
                <label class="section-label">Created Date</label>
                <div>{{ payment.createdDate }}</div>
            </div>
            <div class="mb-2" v-if="payment.requestedDate">
                <label class="section-label">Requested Date</label>
                <div>{{ payment.requestedDate }}</div>
            </div>
            <div class="mb-2" v-if="payment.payee">
                <label class="section-label">Payee</label>
                <div>{{ payment.payee }}</div>
            </div>
            <div class="mb-2" v-if="payment.rechargeTo">
                <label class="section-label">Recharge To</label>
                <div>{{ payment.rechargeTo }}</div>
            </div>
            <div class="mb-2" v-if="payment.paymentMethod">
                <label class="section-label">Payment Method</label>
                <div>{{ payment.paymentMethod }}</div>
            </div>
            <div v-if="canEdit" class="float-right">
                <v-btn-section name="edit-details" @click.stop="editDetails">
                    <v-icon>mdi-pencil-outline</v-icon>
                </v-btn-section>
            </div>
            <div class="mb-2">
                <label class="section-label">Details</label>
                <div v-if="payment.details" v-html="payment.details"></div>
                <div v-else><i>None</i></div>
            </div>
            <div class="mb-2">
                <label class="section-label">Transaction Items</label>
                <transaction-list
                    :editable="canEdit"
                    :payment="rawPayment"
                    @update="load"
                />
            </div>
            <v-btn
                block
                large
                color="primary"
                v-if="primaryAction && canProgress"
                class="mt-6"
                @click="primaryAction.handler()"
                :disabled="!isValid"
            >
                <v-icon left>{{ primaryAction.icon }}</v-icon>
                {{ primaryAction.text }}
            </v-btn>
        </div>
    </v-container>
</template>
<script>
import payments from "./paymentsAgent.js";
import participants from "@/views/Participants/participantsAgent.js";
import VBtnSection from "@/components/VBtnSection";
import tasksAgent from "../Tasks/tasksAgent.js";
import sharedoMenuService from "../../services/sharedo/sharedoMenuService.js";
import sharedoActionService from "../../services/sharedo/sharedoActionService.js";

const PaymentDetailsForm = () => import("./PaymentDetailsForm");
const TransactionList = () => import("./TransactionList");
const TransitionForm = () => import("./TransitionForm");

const ERS_DAMAGES_PORTAL_CODES = 500000080;

export default {
    components: { VBtnSection, TransactionList },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            loading: true,
            rawPayment: null,
            participants: [],
            canProgress: false,
            primaryAction: null,
            phaseInfo: null,
            permissions: [],
        };
    },
    computed: {
        canEdit: function () {
            return (
                this.permissions.includes("core.sharedo.update") &&
                this.phaseInfo &&
                [
                    "vm-payment-request-phases-engineer-draft",
                    "vm-payment-request-phases-engineer-awaiting-approval-rejected",
                ].includes(this.phaseInfo.systemName)
            );
        },
        payment: function () {
            const rechargeTo = this.participants.find(
                (participant) =>
                    participant.odsId ===
                    this.rawPayment.aspectData.paymentRequest.rechargeToOdsId
            );

            return {
                title: this.rawPayment.title,
                phaseName: this.rawPayment.phaseName,
                reference: this.rawPayment.aspectData.paymentRequest.reference,
                createdDate: this.$moment(
                    this.rawPayment.aspectData.createdDate
                ).format("DD/MM/YYYY HH:mm"),
                requestedDate: this.$moment(
                    this.rawPayment.aspectData.requestedDate
                ).format("DD/MM/YYYY HH:mm"),
                payee: this.rawPayment.aspectData.paymentRequest
                    .supplierOdsName,
                rechargeTo: rechargeTo ? rechargeTo.odsName : "",
                paymentMethod:
                    this.rawPayment.aspectData.paymentRequest.paymentMethod ===
                    ERS_DAMAGES_PORTAL_CODES
                        ? "ERS / Damages Portal Codes"
                        : "",
                details: this.rawPayment.aspectData.paymentRequest.details,
            };
        },

        isValid: function () {
            var i = 0;
            if (!this.hasTransactions) i++;
            return i === 0;
        },

        hasTransactions: function () {
            return (
                this.rawPayment.aspectData.paymentRequest.transactionItems
                    .length > 0
            );
        },
    },
    methods: {
        load: async function () {
            try {
                this.rawPayment = await payments.getPayment(this.id);

                this.participants = await participants.getParticipants(
                    this.rawPayment.parentSharedoId
                );

                tasksAgent
                    .getPermissions(this.id)
                    .then((perm) => {
                        this.permissions = perm;

                        this.canProgress = perm.includes(
                            "core.sharedo.progress.milestone"
                        );
                    })
                    .catch(console.error);

                tasksAgent
                    .getPhaseInfo(this.id)
                    .then((info) => {
                        this.phaseInfo = info;

                        var transOpts = {
                            sharedoId: this.id,
                            callback: this.afterTransitionTo.bind(this),
                        };

                        // Get primary action
                        this.primaryAction = this.phaseInfo.availableTransitions
                            .filter((t) => t.isUserDriven && t.isOptimumPath)
                            .map((t) => {
                                var transTo = Object.assign({}, t, transOpts);

                                return {
                                    text: t.name,
                                    icon:
                                        t.toPhaseIconClass ||
                                        "mdi-fast-forward-outline",
                                    handler: this.handleTransition.bind(
                                        this,
                                        transTo
                                    ),
                                };
                            })[0];
                    })
                    .catch(console.error);

                this.loading = false;
            } catch (error) {
                console.error(error);
            }
        },

        editDetails: function () {
            this.$coreUi.dialog(
                PaymentDetailsForm,
                {
                    id: this.id,
                    details: this.payment.details,
                },
                {
                    closing: async (result) => {
                        if (result) {
                            await this.load();
                        }
                    },
                }
            );
        },

        showActionSheet: function () {
            var items = sharedoMenuService.getSharedoActionsMenu({
                id: this.id,
                rolePermissions: this.permissions,
                phaseInfo: this.phaseInfo,
                afterTransitionTo: this.afterTransitionTo.bind(this),
                includeChronologyLink: false,
                includeParticipantsLink: false,
            });

            this.$coreUi.actionSheet({
                items: items,
            });
        },

        handleTransition(opts) {
            if (
                opts.toPhaseSystemName ===
                "vm-payment-request-phases-engineer-awaiting-approval"
            ) {
                // Special case: e-sig phase guard and transition comment
                this.$coreUi.dialog(TransitionForm, opts, {
                    closing: async (result) => {
                        if (result) {
                            await this.load();
                        }
                    },
                });
            } else {
                sharedoActionService.confirmTransitionTo(opts);
            }
        },

        afterTransitionTo(opts) {
            if (opts && opts.shouldCloseUi) this.$router.back();
            else this.load();
        },
    },
    mounted: async function () {
        await this.load();
    },
};
</script>
