import { SharedoFetch } from '@sharedo/mobile-core';

function getParticipants(sharedoId) {
    return SharedoFetch.get(`/api/sharedo/${sharedoId}/participants`);
}

function getParticipantDetails(entityType, odsId) {
    return SharedoFetch.get(`/api/aspects/ods/${entityType}/${odsId}`)
}

function getLocations(odsId) {
    return SharedoFetch.get(`/api/ods/locations/for/${odsId}`);
}

function getLocation(locationId) {
    return SharedoFetch.get(`/api/aspects/ods/locations/${locationId}`);
}

export default {
    getParticipants,
    getParticipantDetails,
    getLocations,
    getLocation
}